.color-input {
  /* padding: 10px 100px; */
  margin: 1rem 0;
  font-size: 1.5rem;
text-align: center;
  /* max-width: 5rem; */
  min-width: 2em;
  border: 3px white solid;
;
}
.color-shower {
  /* border: 3px red solid; */
  /* max-width: 70%; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 3px rgb(255, 255, 255) solid;
}

.selected-color-contianer {
  max-width: 70%;
  text-align: center;
  margin: 3px 0;
  padding: 10px 0;
}
.selected-color-contianer h1, h2 {
  display: inline;
  margin: 10px 0;
  text-shadow: -1px -1px 0 #5d4d4d, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
button {
  cursor: pointer;
}
button.disabled {
 cursor: not-allowed !important;
}
.App {
  margin: 60px 2em;
  text-align: center;
}
@media screen and (max-width: 799px) {
  #ad {
    display: none;
  }
}

.bp {
  font-size: large;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
